import React from "react";
const Header = () => {
  return (
    <header className="line-header bg-green line-header">
      <div className="title-header align-items-center text-center text-white">
        イースマイルLINE発注
      </div>
    </header>
  );
};

export default Header;
